/* eslint-disable @typescript-eslint/space-before-function-paren */
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import Loading from './common/notifications/Loading';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { CLAIM_ROUTES } from './routes/claims';
import { PDG_ROUTES } from './routes/pdg';

const Dashboard = lazy(async () => await import('components/home/Home'));
const Welcome = lazy(async () => await import('components/welcome/Welcome'));
const PageNotFound = lazy(async () => await import('common/pages/PageNotFound'));

export function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route index element={<Welcome />} />
        <Route path="home" element={<Dashboard />} />
        <Route element={<PrivateRoute />}>
          {CLAIM_ROUTES.map(({ path, element: Element }, i) => {
            return <Route key={i} path={path} element={<Element />} />;
          })}
          {PDG_ROUTES.map(({ path, element: Element }, i) => {
            return <Route key={i} path={path} element={<Element />} />;
          })}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}
