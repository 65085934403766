const colorPallete = {
  transparent: 'transparent',
  black: {
    50: '#eaf8fb',
    100: '#cee1e4',
    200: '#b0cbd0',
    300: '#91b5bd',
    400: '#729daa',
    500: '#588290',
    600: '#446370',
    700: '#304650',
    800: '#1b2a31',
    900: '#021014'
  },
  white: '#fff',
  primary: {
    // generated from smart-swatch

    50: '#e6eeff',
    100: '#c0cbf5',
    200: '#96a8e8',
    300: '#6e85de',
    400: '#4562d3',
    500: '#2d48ba',
    600: '#213891',
    700: '#172869',
    800: '#0b1841',
    900: '#02081b'
  },
  secondary: {
    50: '#ffe3e8',
    100: '#feb6bf',
    200: '#f88894',
    300: '#f3596b',
    400: '#ee2a41',
    500: '#d51127',
    600: '#a60a1e',
    700: '#770515',
    800: '#4a010b',
    900: '#1f0001'
  },
  gray: {
    50: '#f7fafc',
    900: '#171923'
  },
  nav: {
    50: '#efefff',
    100: '#d0d3e5',
    200: '#b3b5cf',
    300: '#9497b8',
    400: '#767aa3',
    500: '#5c6089',
    600: '#484b6c',
    700: '#32354e',
    800: '#1e2032',
    900: '#070b18'
  }
};

export default colorPallete;
