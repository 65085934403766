import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type SelectOption } from './../../interfaces/option';

interface ClaimCategoryState {
  categories: SelectOption[];
}

const initialState: ClaimCategoryState = { categories: [] };

export const claimCategoriesSlice = createSlice({
  name: 'claim_categories',
  initialState,
  reducers: {
    persistClaimCategoriesOptions: (state, action: PayloadAction<SelectOption[]>) => {
      state.categories = action.payload;
    }
  }
});

export const { persistClaimCategoriesOptions } = claimCategoriesSlice.actions;

export default claimCategoriesSlice.reducer;
