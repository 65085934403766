import React from 'react';
import AppChakraProvider from './AppChakraProvider';
import ReactQueryClientProvider from './ReactQueryClientProvider';
import ReduxProvider from './ReduxProvider';

interface Props {
  children: React.ReactNode;
}

export const AppProviders = ({ children }: Props) => {
  return (
    <ReactQueryClientProvider>
      <ReduxProvider>
        <AppChakraProvider>{children}</AppChakraProvider>
      </ReduxProvider>
    </ReactQueryClientProvider>
  );
};
