import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import claimCategoriesReducer from '../slices/claim-categories/claimCategoriesSlice';
import currenciesReducer from '../slices/currencies/currenciesSlice';
import homePageReducer from '../slices/home-page/homePageSlice';
import receiptsReducer from '../slices/receipts/receiptsSlice';
import sidebarReducer from '../slices/sidebar/sidebarSlice';
import authReducer from './auth-slice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['homePage', 'receipts']
};

const rootReducer = combineReducers({
  auth: authReducer,
  claimCategories: claimCategoriesReducer,
  currencies: currenciesReducer,
  sidebar: sidebarReducer,
  homePage: homePageReducer,
  receipts: receiptsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
