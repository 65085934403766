import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import { appTheme } from '../theme';

interface Props {
  children: React.ReactNode;
}

const AppChakraProvider = ({ children }: Props) => {
  return (
    <>
      <ColorModeScript initialColorMode={appTheme.config.initialColorMode} />
      <ChakraProvider theme={appTheme}>{children}</ChakraProvider>
    </>
  );
};

export default AppChakraProvider;
