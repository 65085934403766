import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
  isOpen: boolean;
}

const initialState: SidebarState = { isOpen: false };

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    persistSidebarState: (state, action: PayloadAction<SidebarState>) => {
      state.isOpen = action.payload.isOpen;
    }
  }
});

export const { persistSidebarState } = sidebarSlice.actions;

export default sidebarSlice.reducer;
