import { useQuery } from '@tanstack/react-query';
import { getAccessToken, getRequest } from 'http/index';
import type { User } from 'interfaces/auth';
import { useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { fetchingUserDetails, login as loginAction, logout as logoutAction } from 'store/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface AuthProps {
  fetchUserInfo?: boolean;
}

export const useAuth = (props: AuthProps = {}) => {
  const dispatch = useAppDispatch();
  const { fetchUserInfo = true } = props ?? {};
  const auth = useAppSelector((state) => state.auth, shallowEqual);

  const login = (user: User) => {
    if (user === null || user === undefined) {
      return;
    }
    // todo: fix bug causing infinite loading
    dispatch(loginAction(user));
  };

  const logout = () => dispatch(logoutAction());

  // todo:remove this line
  useEffect(() => {
    login({
      name: 'John Njoroge',
      email: 'john.njoroge@wiocc.net',
      jobTitle: 'IT & Systems Engineer',
      role: 'supervisor',
      supervisor: 'Rebecca Kariuki'
    });
  }, []);

  const hasCookies = useMemo(() => {
    const accessToken = getAccessToken();
    return accessToken !== undefined && accessToken !== null;
  }, []);

  const { refetch } = useQuery({
    cacheTime: 0,
    enabled: false,
    queryKey: ['user_details'],
    queryFn: async () => {
      if (!hasCookies) {
        return await Promise.reject(new Error('Invalid request'));
      }
      dispatch(fetchingUserDetails(true));
      return await getRequest({ url: 'getUserDetails' });
    },
    onError: () => {
      dispatch(fetchingUserDetails(false));
    },

    onSuccess: ({ user }: any) => {
      dispatch(fetchingUserDetails(false));
      login(user);
    }
  });

  useEffect(() => {
    if (auth.isFetchingUserDetails || auth.user !== null || auth.isLoggedIn) {
      return;
    }
    if (hasCookies && fetchUserInfo) {
      refetch()
        .then((res) => res)
        .catch((error) => {
          console.log(error);
        });
    }
    if (!hasCookies && auth.isLoggedIn) {
      logout();
    }
  }, [auth.isFetchingUserDetails, auth.user, fetchUserInfo, auth.isLoggedIn]);

  return { auth, login, logout, hasCookies };
};

export type { User };
