import { lazy } from 'react';
import { type AppRoute } from './interfaces';

const NewClaim = lazy(async () => await import('@wiocc-people/web/components/claims/NewClaim'));
const Dashboard = lazy(async () => await import('@wiocc-people/web/components/claims/Dashboard'));
const Expenses = lazy(async () => await import('@wiocc-people/web/components/claims/expenses/Expenses'));
const ExpenseClaim = lazy(async () => await import('@wiocc-people/web/components/claims/expense/Expense'));
const SavedReceipts = lazy(async () => await import('@wiocc-people/web/components/claims/receipts/Receipts'));
const DraftedClaims = lazy(async () => await import('@wiocc-people/web/components/claims/DraftedClaims'));
const PageNotFound = lazy(async () => await import('common/pages/PageNotFound'));

export const CLAIM_ROUTES: AppRoute[] = [
  {
    path: '/claims',
    element: Dashboard
  },
  {
    path: '/claims/new',
    element: NewClaim
  },
  {
    path: '/claims/drafts',
    element: DraftedClaims
  },
  {
    path: '/claims/expenses',
    element: Expenses
  },
  {
    path: '/claims/expenses/:id',
    element: ExpenseClaim
  },
  {
    path: '/claims/admin/expenses',
    element: Expenses
  },
  {
    path: '/claims/supervisor/expenses',
    element: Expenses
  },
  {
    path: '/claims/receipts',
    element: SavedReceipts
  },
  {
    path: '/claims/receipts/:id',
    element: Expenses
  },
  {
    path: '*',
    element: PageNotFound
  }
];
