import Loading from 'common/notifications/Loading';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useAuth } from './useAuth';

export const PrivateRoute = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth.isLoggedIn && !auth.isFetchingUserDetails) {
    return <Navigate to={`/login?accessed_location=${location.pathname}`} />;
  }

  if (auth.isFetchingUserDetails) {
    return <Loading />;
  }

  return <Outlet />;
};
