import { lazy } from 'react';
import { type AppRoute } from './interfaces';

const Dashboard = lazy(async () => await import('@wiocc-people/web/components/pdg/Dashboard'));
const NewPdg = lazy(async () => await import('@wiocc-people/web/components/pdg/NewPdg'));
const SinglePdg = lazy(async () => await import('@wiocc-people/web/components/pdg/SinglePdg'));
const EditPdg = lazy(async () => await import('@wiocc-people/web/components/pdg/EditPdg'));
const AdminView = lazy(async () => await import('@wiocc-people/web/components/pdg/AdminView'));
const HrView = lazy(async () => await import('@wiocc-people/web/components/pdg/HrView'));
const SupervisorView = lazy(async () => await import('@wiocc-people/web/components/pdg/SupervisorView'));
const PdgDrafts = lazy(async () => await import('@wiocc-people/web/components/pdg/PdgDrafts'));

export const PDG_ROUTES: AppRoute[] = [
  {
    path: '/pdg',
    element: Dashboard
  },
  {
    path: '/pdg/new-pdg',
    element: NewPdg
  },
  {
    path: '/pdg/:id',
    element: SinglePdg
  },
  { path: '/pdg/edit/:id', element: EditPdg },
  { path: '/pdg/drafts', element: PdgDrafts },
  { path: '/pdg/supervisor', element: SupervisorView },
  { path: '/pdg/admin', element: AdminView },
  { path: '/pdg/hr', element: HrView }
];
