import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type CurrencyListItem } from '@wiocc-people/web/components/claims/components/constants/interfaces';
import { type SelectOption } from './../../interfaces/option';

interface CurrenciesState {
  currencies: SelectOption[];
  currenciesList: CurrencyListItem[];
}

const initialState: CurrenciesState = { currencies: [], currenciesList: [] };

export const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    persistCurrencyOptions: (state, action: PayloadAction<SelectOption[]>) => {
      state.currencies = action.payload;
    },
    persistCurrencyList: (state, action: PayloadAction<CurrencyListItem[]>) => {
      state.currenciesList = action.payload;
    }
  }
});

export const { persistCurrencyOptions, persistCurrencyList } = currenciesSlice.actions;

export default currenciesSlice.reducer;
