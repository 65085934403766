import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type EncodedAttachment } from '@wiocc-people/web/common/file/interfaces';

interface HomePageState {
  receiptsToAttach: EncodedAttachment[];
}

const initialState: HomePageState = {
  receiptsToAttach: []
};

export const receiptsSlice = createSlice({
  name: 'receipts',
  initialState,
  reducers: {
    persistAttachReceiptsState: (state, action: PayloadAction<HomePageState>) => {
      state.receiptsToAttach = action.payload.receiptsToAttach;
    }
  }
});

export const { persistAttachReceiptsState } = receiptsSlice.actions;

export default receiptsSlice.reducer;
