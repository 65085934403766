import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type GroupClaim } from './../../components/claims/components/constants/interfaces';

interface ClaimEntity {
  data: GroupClaim[];
  isFetching: boolean;
  isError: boolean;
}

interface HomePageState {
  claims: ClaimEntity;
}

const initialState: HomePageState = {
  claims: {
    data: [],
    isError: false,
    isFetching: true
  }
};

export const homePageSlice = createSlice({
  name: 'home-page',
  initialState,
  reducers: {
    persistHomePageClaimsState: (state, action: PayloadAction<HomePageState>) => {
      state.claims = action.payload.claims;
    }
  }
});

export const { persistHomePageClaimsState } = homePageSlice.actions;

export default homePageSlice.reducer;
