import BeatLoader from 'react-spinners/BeatLoader';

const Loading = () => {
  return (
    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
      <BeatLoader size={8} color="rgb(10,120,190)" />
    </div>
  );
};

export default Loading;
