import axios, { type AxiosError, type AxiosRequestConfig, type Method } from 'axios';

const baseURL =
  process.env.NODE_ENV === 'production' ? 'https://api.people.wiocc.net' : `${process.env.REACT_APP_DEV_API_URL}`;

const axiosInstance = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: true
});

export interface IsHttpResponse<T> {
  data: T;
  error: string | null;
  success: boolean;
}
export interface HttpResults<T> {
  count: number;
  results: T;
  data?: unknown;
}
export interface GetProps {
  url: string;
}

export interface DeleteProps {
  url: string;
}

export interface PostProps extends GetProps {
  data?: Record<string, unknown>;
}
export interface HttpConfig extends PostProps {
  method: Method;
}

const ACCESS_TOKEN = 'csrf_access_token=';

export const getAccessToken = () => {
  if (document.cookie === null || document.cookie === undefined) {
    return null;
  }
  return (
    document.cookie
      ?.split('; ')
      .find((row) => row.startsWith(ACCESS_TOKEN))
      ?.split('=')[1] ?? null
  );
};

const clearAccessToken = () => {
  document.cookie = `${ACCESS_TOKEN};expires=` + new Date(0).toUTCString();
};

const makeHttpCall = async (props: HttpConfig) => {
  try {
    const axiosRequestConfig: AxiosRequestConfig = props;
    if (document.cookie.replace(/\s/g, '') === '') {
      if (axiosRequestConfig.headers === null || axiosRequestConfig.headers === undefined) {
        axiosRequestConfig.headers = {};
      }
      // if (props.url.includes('group_claims') || props.url.includes('claims')) {
      //   axiosRequestConfig.headers['Content-Type'] = 'multipart/form-data';
      // }
      axiosRequestConfig.headers['X-CSRF-TOKEN'] = getAccessToken();
    }
    // todo: find solution to the "Unsafe return of an `any` typed value" eslint error
    return (await axiosInstance.request(axiosRequestConfig)).data;
    // return data.data;
  } catch (e: unknown) {
    const error = e as AxiosError;
    const status = error.response?.status;
    if (status === 422) {
      clearAccessToken();
      // sessionStorage.clear();
    }
    return e;
  }
};

export const getRequest = async <T>({ url }: GetProps): Promise<T | IsHttpResponse<T> | HttpResults<T>> => {
  return await makeHttpCall({ url, method: 'GET' });
};

export const deleteRequest = async <T>({ url }: DeleteProps): Promise<T | IsHttpResponse<T> | HttpResults<T>> => {
  return await makeHttpCall({ url, method: 'DELETE' });
};

export const postRequest = async <T>({ url, data }: PostProps): Promise<T> => {
  return await makeHttpCall({ url, method: 'POST', data });
};

export const patchRequest = async <T>({ url, data }: PostProps): Promise<T> => {
  return await makeHttpCall({ url, method: 'PATCH', data });
};

export const httpRequest = async <T>({ url, data, method }: HttpConfig): Promise<T> => {
  return await ((await makeHttpCall({ url, method, data })) as Promise<T>);
};

export type HttpMethod = Method;
