import { extendTheme, withDefaultColorScheme, withDefaultSize } from '@chakra-ui/react';
import colorPallete from './color-pallete';

// todo:update correct theme
const themeConfig = {
  colors: colorPallete,
  fonts: {
    // todo: update to use monteserrat font
    body: "'Montserrat', sans-serif"
  }
};

export const appTheme = extendTheme(
  themeConfig,
  withDefaultColorScheme({
    colorScheme: 'primary',
    components: ['Button', 'Table', 'Heading']
  }),
  withDefaultColorScheme({
    colorScheme: 'secondary',
    components: ['Badge', 'Avatar']
  }),
  withDefaultSize({ size: 'sm' })
);
